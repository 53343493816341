import React from 'react';
import { ButtonProps } from '@mui/material';
import { Button } from '@odl/core';

type Props = ButtonProps;

const ODLContainedButton: React.FC<Props> = ({ children, ...otherProps }) => {
  return (
    <Button variant={'contained'} {...otherProps}>
      {children}
    </Button>
  );
};

export default ODLContainedButton;
