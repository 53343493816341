import { IRoleService } from 'services/role/RoleService.types';
import { AddRoleFormValues, IRoleEntity, RolesUtil, UpdateRoleFormValues } from 'models/Role.model';
import { Cradle } from 'services/serviceContainer.types';
import get from 'lodash/get';
import toString from 'lodash/toString';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';

export class RoleService implements IRoleService {
  private readonly apiClient: Cradle['apiClient'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: { apiClient: Cradle['apiClient']; i18n: Cradle['i18n'] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchRoles() {
    const response = await this.apiClient.protectedApi.get(`/roles`);

    const jsonArr = get(response.data, 'roles');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch roles`));
    }

    const roles = RolesUtil.parseRoles(jsonArr);

    return {
      roles,
    };
  }

  public async fetchRoleById(roleId: string) {
    const response = await this.apiClient.protectedApi.get(`/roles/${roleId}`);
    const json = response.data;
    const role = RolesUtil.parseRole(json);
    return {
      role,
    };
  }

  public async addNewRole(args: AddRoleFormValues) {
    try {
      const response = await this.apiClient.protectedApi.post('/roles', args);
      const id = toString(get(response.data, 'id'));
      return { id };
    } catch (error: any) {
      const message = get(error, 'response.data.message');
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(message));
    }
  }

  public async updateRole(args: UpdateRoleFormValues): Promise<IRoleEntity> {
    try {
      const response = await this.apiClient.protectedApi.put(`/roles/${args.id}`, args);
      const json = response.data;
      const role = RolesUtil.parseRole(json);
      return role;
    } catch (error: any) {
      const message = get(error, 'response.data.message');
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(message));
    }
  }

  public async deleteRole(roleId: string): Promise<void> {
    const response = await this.apiClient.protectedApi.delete(`/roles/${roleId}`);
    return response.data;
  }
}
