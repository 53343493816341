import { IApiClient } from 'services/api-client/ApiClient.types';
import axios, { AxiosInstance } from 'axios';
import { BUILD_AU_API_ENDPOINT, LICENCE_SERVER_API_AUTH_URL } from 'constants/configs';
import { Cradle } from 'services/serviceContainer.types';
import merge from 'lodash/merge';

export class ApiClient implements IApiClient {
  private readonly cognitoService: Cradle['cognitoService'];

  private authInstance: AxiosInstance | null = null;
  private protectedInstance: AxiosInstance | null = null;

  constructor(args: Pick<Cradle, 'cognitoService'>) {
    this.cognitoService = args.cognitoService;
  }

  get authApi(): AxiosInstance {
    if (this.authInstance) {
      return this.authInstance;
    }

    const instance = axios.create({
      baseURL: LICENCE_SERVER_API_AUTH_URL,
    });

    return instance;
  }

  get protectedApi(): AxiosInstance {
    if (this.protectedInstance) {
      return this.protectedInstance;
    }

    const instance = axios.create({
      baseURL: BUILD_AU_API_ENDPOINT,
    });

    instance.interceptors.request.use(async (config) => {
      const idToken = await this.cognitoService.getIdToken();

      const newConfig = merge({}, config, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return newConfig;
    });

    return instance;
  }
}
