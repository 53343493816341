import React from 'react';

import { styled, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout/PageLayout';
import MainLayout from 'components/MainLayout/MainLayout';
import AppSideNavigation from 'components/AppSideNavigation/AppSideNavigation';
import { AppSideNavigationTypes } from 'components/AppSideNavigation/AppSideNavigation.types';
import BrownContentBox from 'components/BrownContentBox/BrownContentBox';
import { useStoreDispatch } from 'store/hooks';
import { ApplicationTab } from 'models/Application.model';
import { fetchUserApplications } from 'store/domain-data/application/application';
import ApplicationDetailsSidePanel from 'components/ApplicaitonDetailsSidePanel/ApplicationDetailsSidePanel';
import DetailsPanel from 'components/DetailsPanel/DetailsPanel';
import ApplicationsList from 'components/ApplicationsList/ApplicationsList';

const ApplicationsPage = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [currentTab, setCurrentTab] = React.useState(ApplicationTab.Active);

  React.useEffect(() => {
    dispatch(fetchUserApplications());
  }, [dispatch]);

  const handleOnClickTab = React.useCallback((activeTab: ApplicationTab) => {
    setCurrentTab(activeTab);
  }, []);

  return (
    <MainLayout
      navigation={<AppSideNavigation activeMenuItemType={AppSideNavigationTypes.Applications} />}
      rightColumn={
        <DetailsPanel>
          <ApplicationDetailsSidePanel />
        </DetailsPanel>
      }
    >
      <PageLayout title={t('Applications')}>
        <StyledApplicationsPage>
          <BrownContentBox>
            <TabContext value={currentTab}>
              <StyledTabList aria-label="applications tab list">
                <StyledTab
                  disableRipple
                  label={t('Active')}
                  value={ApplicationTab.Active}
                  onClick={() => handleOnClickTab(ApplicationTab.Active)}
                />
                <StyledTab
                  disableRipple
                  disabled
                  label={t('Awaiting Approval')}
                  value={ApplicationTab.AwaitingApproval}
                  onClick={() => handleOnClickTab(ApplicationTab.AwaitingApproval)}
                />
                <StyledTab
                  disableRipple
                  disabled
                  label={t('Determined')}
                  value={ApplicationTab.Determined}
                  onClick={() => handleOnClickTab(ApplicationTab.Determined)}
                />
              </StyledTabList>
              <StyledTabPanel value={ApplicationTab.Active}>
                <ApplicationsList type={ApplicationTab.Active} />
              </StyledTabPanel>
            </TabContext>
          </BrownContentBox>
        </StyledApplicationsPage>
      </PageLayout>
    </MainLayout>
  );
};

export default ApplicationsPage;

const StyledApplicationsPage = styled('main')(({ theme }) => ({}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  paddingBottom: theme.spacing(2),
  borderBottom: `${theme.palette.background.default} solid 3px`,
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  marginLeft: '24px',
  paddingBottom: '4px',
  paddingLeft: '0px',
  paddingRight: '0px',
}));
