import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, Grid, Link, styled, TextField, Typography } from '@mui/material';
import { Button } from '@odl/core';
import FieldLabel from 'components/AuthUI/FieldLabel';
import { urlHelper } from 'components/RouteController/functions/urlHelper';
import { useStoreSelector } from 'store/hooks';
import { selectIsAuthenticating } from 'store/app-state/authentication/authentication';

type Props = {
  onPasswordSubmit: (password: string) => void;
  email: string;
  loginError?: string;
  onEditEmail: () => void;
};

const PasswordSection: React.FC<Props> = ({ onPasswordSubmit, email, loginError, onEditEmail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState('');
  const [passwordHide, setPasswordHide] = React.useState(true);
  const [showRequiredPasswordError, setShowRequiredPasswordError] = React.useState(false);
  const [showLoginError, setShowLoginError] = React.useState(!!loginError);

  React.useEffect(() => {
    setShowLoginError(!!loginError);
  }, [loginError]);

  const isAuthenticating = useStoreSelector(selectIsAuthenticating);

  const handleOnForgotPasswordClick = React.useCallback(() => {
    navigate(urlHelper.getURLForPasswordReset());
  }, [navigate]);

  const handleOnBackToLoginClick = React.useCallback(() => {
    setShowRequiredPasswordError(false);
    onEditEmail();
  }, [onEditEmail]);

  const handleOnSignInClick = React.useCallback(() => {
    if (password.length === 0) {
      setShowRequiredPasswordError(true);
      return;
    }
    onPasswordSubmit(password);
  }, [onPasswordSubmit, password]);

  const handleOnPasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setShowRequiredPasswordError(false);
    setShowLoginError(false);
  }, []);

  const handleOnPasswordViewToggle = React.useCallback(() => {
    setPasswordHide((currentState) => !currentState);
  }, []);

  const onKeyDown = React.useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        handleOnSignInClick();
      }
    },
    [handleOnSignInClick],
  );

  React.useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-password')?.focus();
  }, []);

  const isSignInButtonDisabled = React.useMemo(() => {
    if (isAuthenticating) {
      return true;
    }
    if (showLoginError) {
      return true;
    }
    if (showRequiredPasswordError) {
      return true;
    }
    return false;
  }, [isAuthenticating, showLoginError, showRequiredPasswordError]);

  return (
    <StyledPasswordSection container item>
      <StyledFormControls container>
        <StyledFormControl fullWidth>
          <FieldLabel required label={t('Email address')} />
          <StyledTextField id="textfield-email" value={email} fullWidth disabled variant="outlined" />
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <FieldLabel required label={t('Password')} />
          <TextField
            id="textfield-password"
            type={passwordHide ? 'password' : 'text'}
            fullWidth
            variant="outlined"
            placeholder={t('Password')}
            error={showLoginError}
            value={password}
            onChange={handleOnPasswordChange}
            onKeyDown={onKeyDown}
            InputProps={{
              endAdornment: (
                <IconBox
                  className={passwordHide ? 'icon icon-eye' : 'icon icon-eye-off'}
                  onClick={handleOnPasswordViewToggle}
                />
              ),
            }}
          />

          {showLoginError && (
            <StyledFormHelperText id="component-helper-text" error>
              <span className="icon icon-alert odlTextInput__icon" /> {loginError}
            </StyledFormHelperText>
          )}

          {showRequiredPasswordError && (
            <StyledFormHelperText id="component-helper-text" error>
              <span className="icon icon-alert odlTextInput__icon" /> {t('Password is required.')}
            </StyledFormHelperText>
          )}
        </StyledFormControl>
      </StyledFormControls>
      <Grid item>
        <ODLButton
          variant="contained"
          size="large"
          fullWidth
          onClick={handleOnSignInClick}
          disabled={isSignInButtonDisabled}
        >
          {t('Sign in')}
        </ODLButton>
      </Grid>
      <StyledLinks container>
        <PointerLink onClick={handleOnForgotPasswordClick}>
          <Typography>{t('Forgot Password')}</Typography>
        </PointerLink>
        <PointerLink onClick={handleOnBackToLoginClick}>
          <Typography>{t('Back to sign in')}</Typography>
        </PointerLink>
      </StyledLinks>
    </StyledPasswordSection>
  );
};

export default PasswordSection;

const PointerLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '& .MuiTypography-root': {
    color: theme.palette.objective.light.primary.light,
  },
  '& .MuiTypography-root:hover': {
    textDecoration: 'underline',
  },
}));

const ODLButton = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    backgroundColor: theme.palette.objective.light.background.papershade,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .Mui-disabled': {
    backgroundColor: theme.palette.objective.light.background.papershade,
  },
}));

const IconBox = styled('div')({
  '&.icon-eye': {
    fontSize: '18px',
  },
  cursor: 'pointer',
});

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: '1em',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  ...theme.mixins.flexGap('8px'),
}));

const StyledFormControls = styled(Grid)(({ theme }) => ({
  ...theme.mixins.flexGap('16px'),
}));

const StyledPasswordSection = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  ...theme.mixins.flexGap('24px'),
}));

const StyledLinks = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingBottom: '8px',
}));
