// Color palette should align with ODLv2
// Refer: https://www.figma.com/file/B31nweVy3o7NJhEGH7Pmud/ODL-Pro-Light-Colours?node-id=0%3A1
export const objectivePalette = {
  light: {
    background: {
      paper: '#FFFFFF',
      papershade: '#F5F5F5',
      default: '#EDF1F5',
      error: '#F7E4E6',
      warning: '#FDEED3',
      info: '#E0F3FE',
      success: '#DFF6DF',
      hover: '#E0F3FE',
    },
    text: {
      primary: '#32373F',
      secondary: '#525965',
      tertiary: '#707070',
      disabled: '#ACACAC',
    },
    divider: '#D1D1D1',
    grey: {
      50: '#FAFAFA',
      100: '#F8F8F8',
      200: '#F7f7f7',
      300: '#F5F5F5',
      400: '#E8E8E8',
      500: '#D1D1D1',
      600: '#ACACAC',
      700: '#707070',
      800: '#525965',
      900: '#32373f',
      A100: '#85b0ff',
      A200: '#528fff',
      A400: '#1f6fff',
      A700: '#055eff',
    },
    primary: {
      main: '#3560C1',
      light: '#0037B1',
      dark: '#00277F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#CCDBFE',
      light: '#DAE8FF',
      dark: '#B2CAFE',
      contrastText: '#32373F',
    },
    error: {
      main: '#D0000A',
      light: '#E3001E',
      dark: '#B30003',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#F3AD2E',
      light: '#FBE6C0',
      dark: '#ED931D',
      contrastText: '#32373F',
    },
    info: {
      main: '#007DC3',
      light: '#2691CC',
      dark: '#004DA1',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#2A7D2A',
      light: '#4A914A',
      dark: '#0F4D0F',
      contrastText: '#FFFFFF',
    },
    brand: {
      alpha: '#1F428F',
      build: '#5da10c',
      connect: '#48A84E',
      ecm: '#007DC3',
      inform: '#9D3F97',
      keystone: '#C63906',
      office: '#D83B00',
      perform: '#BD2841',
      reach: '#20908B',
      redact: '#26C5BE',
      regworks: '#36A56F',
      teams: '#5558AF',
      trapeze: '#f3ad2e',
    },
    chip: {
      paper: {
        main: '#FFFFFF',
        light: '#d1d1d1',
        contrastText: '#525965',
      },
      default: {
        main: '#EDF1F5',
        contrastText: '#525965',
      },
      blue: {
        main: '#E5F5FE',
        contrastText: '#0F307A',
      },
      mint: {
        main: '#D0FAF7',
        contrastText: '#1F787A',
      },
      green: {
        main: '#E4F7E4',
        contrastText: '#31622C',
      },
      olive: {
        main: '#DAE3BF',
        contrastText: '#54622C',
      },
      yellow: {
        main: '#FFFBCE',
        contrastText: '#A15202',
      },
      orange: {
        main: '#FCEEDA',
        contrastText: '#C93713',
      },
      red: {
        main: '#F8E8EA',
        contrastText: '#C2185B',
      },
      pink: {
        main: '#F7E2F9',
        contrastText: '#9C27B0',
      },
      purple: {
        main: '#DAD8FD',
        contrastText: '#1A1C93',
      },
      brown: {
        main: '#E1D5C7',
        contrastText: '#4F3E34',
      },
    },
  },
  dark: {
    background: {
      paper: '#28292B',
      papershade: '#3C3D3F',
      default: '#1D1D1D',
      error: '#4A1B1B',
      warning: '#4A481B',
      info: '#1B2E4A',
      success: '#1B4A25',
      hover: '#48494B',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#E1E7F2',
      tertiary: '#96A5BD',
      disabled: '#6C7789',
    },
    divider: '#8A9AB3',
    grey: {
      50: '#CCCDCE',
      100: '#88898C',
      200: '#6F7073',
      300: '#3C3D3F',
      400: '#38393B',
      500: '#8A9AB3',
      600: '#6C7789',
      700: '#96A5BD',
      800: '#E1E7F2',
      900: '#ffffff',
      A100: '#85b0ff',
      A200: '#528fff',
      A400: '#1f6fff',
      A700: '#055eff',
    },
    primary: {
      main: '#A7C2FD',
      light: '#D3E1FE',
      dark: '#7C9FFC',
      contrastText: '#32373F',
    },
    secondary: {
      main: '#464F62',
      light: '#CCDBFE',
      dark: '#7C9FFC',
      contrastText: '#32373F',
    },
    error: {
      main: '#FC9BA5',
      light: '#FFB9C0',
      dark: '#FF5664',
      contrastText: '#32373F',
    },
    warning: {
      main: '#F3BE5F',
      light: '#F7D28F',
      dark: '#EA9932',
      contrastText: '#32373F',
    },
    info: {
      main: '#5FACF3',
      light: '#AFD6F9',
      dark: '#4392ED',
      contrastText: '#32373F',
    },
    success: {
      main: '#40D6BD',
      light: '#A0EBDE',
      dark: '#1CBC98',
      contrastText: '#32373F',
    },
    brand: {
      alpha: '#709DFF',
      connect: '#73E57B',
      ecm: '#1CAEFF',
      inform: '#DE81D8',
      keystone: '#F27F5A',
      office: '#F7894A',
      perform: '#FF6E9C',
      reach: '#20908B',
      redact: '#26C5BE',
      regworks: '#47D992',
      teams: '#A6A7DC',
      trapeze: '#F3BE5F',
    },
    chip: {
      paper: {
        main: '#28292B',
        light: '#8A9AB3',
        contrastText: '#E1E7F2',
      },
      default: {
        main: '#1D1D1D',
        contrastText: '#E1E7F2',
      },
      blue: {
        main: '#082A78',
        contrastText: '#E5F5FE',
      },
      mint: {
        main: '#1F787A',
        contrastText: '#D0FAF7',
      },
      green: {
        main: '#31622C',
        contrastText: '#E4F7E4',
      },
      olive: {
        main: '#54622C',
        contrastText: '#DAE3BF',
      },
      yellow: {
        main: '#A15202',
        contrastText: '#FFFBCE',
      },
      orange: {
        main: '#C93713',
        contrastText: '#FCEEDA',
      },
      red: {
        main: '#C2185B',
        contrastText: '#F8E8EA',
      },
      pink: {
        main: '#9C27B0',
        contrastText: '#F7E2F9',
      },
      purple: {
        main: '#1A1C93',
        contrastText: '#D6CBF6',
      },
      brown: {
        main: '#381A93',
        contrastText: '#E1D5C7',
      },
    },
  },
};
