import { Cradle } from 'services/serviceContainer.types';
import get from 'lodash/get';
import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';
import { IUserEntity, UserFormValues, UserRolesFormValues, UserUtil } from 'models/User.model';
import { IUserService } from 'src/services/user/UserService.types';
import toArray from 'lodash/toArray';

export class UserService implements IUserService {
  private readonly apiClient: Cradle['apiClient'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: { apiClient: Cradle['apiClient']; i18n: Cradle['i18n'] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchUsers(): Promise<{
    users: IUserEntity[];
  }> {
    const response = await this.apiClient.protectedApi.get(`/users`);

    const jsonArr = get(response.data, 'users');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch users`));
    }

    const users = this.parseUsers(jsonArr);

    return {
      users,
    };
  }

  public async fetchUserById(userId: string) {
    const response = await this.apiClient.protectedApi.get(`/users/${userId}`);
    const json = response.data;
    const user = this.parseUser(json);
    return {
      user,
    };
  }

  public async updateUser(args: UserFormValues): Promise<IUserEntity> {
    const response = await this.apiClient.protectedApi.put(`/users/${args.id}`, args);
    const json = response.data;
    const user = this.parseUser(json);
    return user;
  }

  public async updateUserRoles(args: UserRolesFormValues): Promise<IUserEntity> {
    const response = await this.apiClient.protectedApi.put(`/users/${args.id}/roles`, args);
    const json = response.data;
    const user = this.parseUser(json);
    return user;
  }

  private parseUsers(json: any): IUserEntity[] {
    if (!json) {
      return [];
    }

    const users: IUserEntity[] = [];
    for (const user of json) {
      users.push({
        id: toString(get(user, 'id')),
        colour: toNumber(get(user, 'colour')),
        status: UserUtil.parseUserStatus(toString(get(user, 'status'))),
        initials: toString(get(user, 'initials')),
        lastName: toString(get(user, 'lastName')),
        revision: toNumber(get(user, 'revision')),
        userName: toString(get(user, 'userName')),
        firstName: toString(get(user, 'firstName')),
        emailAddress: toString(get(user, 'emailAddress')),
        roles: toArray(get(user, 'roles')),
      });
    }
    return users;
  }

  private parseUser(json: any): IUserEntity {
    const user = {
      id: toString(get(json, 'id')),
      colour: toNumber(get(json, 'colour')),
      revision: toNumber(get(json, 'revision')),
      status: UserUtil.parseUserStatus(toString(get(json, 'status'))),
      initials: toString(get(json, 'initials')),
      lastName: toString(get(json, 'lastName')),
      userName: toString(get(json, 'userName')),
      firstName: toString(get(json, 'firstName')),
      emailAddress: toString(get(json, 'emailAddress')),
      councilCode: toString(get(json, 'councilCode')),
      roles: toArray(get(json, 'roles')),
      phone: toString(get(json, 'phone')),
      mobile: toString(get(json, 'mobile')),
    };
    return user;
  }
}
