import trimEnd from 'lodash/trimEnd';
import { USE_DASHBOARD } from 'constants/configs.ts';
import { RoleTab } from 'models/Role.model';

const getURLForSignIn = () => {
  return '/signin';
};

const getURLForSignedOut = () => {
  return '/signedout';
};

const getURLForNewAccount = () => {
  return '/new-account';
};

const getURLForPasswordReset = () => {
  return '/reset';
};

const getURLForApplicationDetailsPage = (applicationId: string) => {
  return trimEnd(`/application-details/${applicationId}`);
};

const getURLForEditApplicationDetailsPage = (applicationId: string) => {
  return trimEnd(`/application-details/${applicationId}/edit`);
};

const getURLForComplianceCheckListPage = (applicationId: string) => {
  return trimEnd(`/application-details/${applicationId}/compliance-check-list`);
};

const getURLForApplicationsPage = () => {
  return USE_DASHBOARD ? '/applications' : '/';
};

const getURLForUsersAndPermissionsPage = () => {
  return '/users-and-permissions';
};

const getURLForAddARolePage = () => {
  return '/users-and-permissions/add';
};

const getURLForUsersAndPermissionsDetailsPage = (roleId: string, activeTab: RoleTab = RoleTab.Role) => {
  const searchParams = new URLSearchParams();
  searchParams.set('activeTab', activeTab);
  return trimEnd(`/users-and-permissions/${roleId}?${searchParams.toString()}`);
};

const getURLForCreateApplicationPage = () => {
  return '/create-application';
};

const getURLForUserDetailsPage = (userId: string) => {
  return trimEnd(`/users-and-permissions/users/${userId}`);
};

export const urlHelper = {
  getURLForSignIn,
  getURLForSignedOut,
  getURLForNewAccount,
  getURLForPasswordReset,
  getURLForApplicationsPage,
  getURLForApplicationDetailsPage,
  getURLForComplianceCheckListPage,
  getURLForEditApplicationDetailsPage,
  getURLForUsersAndPermissionsPage,
  getURLForUsersAndPermissionsDetailsPage,
  getURLForCreateApplicationPage,
  getURLForAddARolePage,
  getURLForUserDetailsPage,
};
