/* eslint-disable react/display-name */
import { Components } from '@mui/material';
import { OdlThemeType } from '@odl/core';
import { ThemeOptions } from '@mui/material/styles';

const themeComponentOverride = (theme: OdlThemeType & ThemeOptions): Components => ({
  MuiFilledInput: {
    styleOverrides: {
      root: {
        background: theme.palette?.background?.papershade,

        '&:hover, &:active, &:focus': {
          background: theme.palette?.objective.light.grey['400'],
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        textarea: {
          background: theme.palette?.background?.papershade,
          lineHeight: '20px',
          '&:hover, &:active, &:focus': {
            background: theme.palette?.objective.light.grey['400'],
          },
        },

        '.MuiFilledInput-root.Mui-focused': {
          background: theme.palette?.objective.light.grey['400'],
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '14px 6px',
      },
    },
  },
  MuiChip: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        border: 'solid 1px transparent',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '.MuiFormControlLabel-label': {
          fontWeight: 500,
          color: theme.palette?.text?.primary,
        },

        '& .MuiSwitch-root': {
          padding: 0,
          height: 'inherit',
          width: 'inherit',
        },

        '& .MuiSwitch-switchBase': {
          padding: 0,
          top: 6,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            transform: 'translateX(30px)',
          },
        },
        '& .MuiSwitch-track': {
          height: 20,
          borderRadius: 20,
          opacity: 1,
          width: 44,
          backgroundColor: theme.palette?.objective.light.grey[800],
          border: `4px solid ${theme.palette?.background?.paper}`,

          '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="white" d="M12.6667 4.27331L11.7267 3.33331L8.00004 7.05998L4.27337 3.33331L3.33337 4.27331L7.06004 7.99998L3.33337 11.7266L4.27337 12.6666L8.00004 8.93998L11.7267 12.6666L12.6667 11.7266L8.94004 7.99998L12.6667 4.27331Z" /></svg>')`,
            right: 8,
          },
        },

        '& .Mui-checked+.MuiSwitch-track': {
          '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="white" d="M13.4 5.06823L6.06669 12.4016L2.40002 8.7349L3.34002 7.7949L6.06669 10.5149L12.46 4.12823L13.4 5.06823Z"/></svg>')`,
            left: 9,
          },

          backgroundColor: theme.palette?.objective.light.primary.main,
          opacity: '1 !important',
        },
        '& .MuiSwitch-thumb': {
          width: 16,
          height: 16,
          backgroundColor: theme.palette?.objective.light.background.papershade,
          boxShadow:
            'box-shadow: 0px 1px 5px 0px #00000033, box-shadow: 0px 3px 1px -2px #0000001F,box-shadow: 0px 2px 2px 0px #00000024',
        },

        '&:hover .Mui-checked+.MuiSwitch-track': {
          backgroundColor: theme.palette?.objective.light.primary.light,
          borderColor: theme.palette?.background?.paper,
        },
        '&:hover .MuiSwitch-track': {
          borderColor: theme.palette?.objective.light.grey[400],
        },
      },
    },
  },
});

export default themeComponentOverride;
