import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { IUserEntity, UserFormValues, UserRolesFormValues } from 'models/User.model';
import { RootState } from 'store/types';
import { serviceContainer } from 'services/serviceContainer';
import { USE_MOCK_BACKEND } from 'constants/configs';
import toNumber from 'lodash/toNumber';

// Entity Adapter
const entityAdapter = createEntityAdapter<IUserEntity>({
  sortComparer: (a, b) => (USE_MOCK_BACKEND ? toNumber(a.id) - toNumber(b.id) : a.firstName.localeCompare(b.firstName)),
});

// Actions
const upsertUsers = createAction<IUserEntity[]>('domainData/user/upsertUsers');
const upsertUser = createAction<IUserEntity>('domainData/user/upsertUser');

// Thunks
export const fetchUsers = createAsyncThunk('domainData/user/fetchUsers', async (_, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { users } = await userService.fetchUsers();
  thunkAPI.dispatch(upsertUsers(users));
  return users;
});

export const fetchUserById = createAsyncThunk('domainData/user/fetchUser', async (userId: string, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { user } = await userService.fetchUserById(userId);
  thunkAPI.dispatch(upsertUser(user));
  return user;
});

export const updateUser = createAsyncThunk('domainData/user/updateUser', async (args: UserFormValues, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const user = await userService.updateUser(args);
  thunkAPI.dispatch(upsertUser(user));
  return user;
});

export const updateUserRoles = createAsyncThunk(
  'domainData/user/updateUserRoles',
  async (args: UserRolesFormValues, thunkAPI) => {
    const userService = serviceContainer.cradle.userService;
    const user = await userService.updateUserRoles(args);
    thunkAPI.dispatch(upsertUser(user));
    return user;
  },
);

// Reducer
const initialState = entityAdapter.getInitialState();

export const userReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder.addCase(upsertUsers, entityAdapter.upsertMany).addCase(upsertUser, entityAdapter.upsertOne);
});

// Selectors
export const {
  selectById: selectUserEntityById,
  selectIds: selectUserEntityIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUserEntities,
  selectTotal: selectTotalUserEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.user);
