import React from 'react';
import { styled, TableRow, TableRowProps } from '@mui/material';

type Props = TableRowProps & {
  children: React.ReactNode;
};

const ODLTableRow: React.FC<Props> = ({ children, ...otherProps }) => {
  return <StyledTableRow {...otherProps}>{children}</StyledTableRow>;
};

export default ODLTableRow;

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
