import { z } from 'zod';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import toArray from 'lodash/toArray';

export enum UserStatus {
  Blocked = 'Blocked',
  Active = 'Active',
  Retired = 'Retired',
  Inactive = 'Inactive',
  Unknown = 'Unknown',
}

export const userSchema = z.object({
  id: z.string(),
  colour: z.number(),
  status: z.nativeEnum(UserStatus),
  initials: z.string(),
  lastName: z.string(),
  revision: z.number(),
  userName: z.string(),
  firstName: z.string(),
  emailAddress: z.string().email(),
  roles: z.string().array(),
  councilCode: z.string().optional(),
  phone: z.string().optional(),
  mobile: z.string().optional(),
});

export type IUserEntity = z.infer<typeof userSchema>;

export const userFormSchema = z.object({
  id: z.string(),
  firstName: z
    .string({ required_error: 'First name is required' })
    .min(3, 'Minimum 3 characters required')
    .max(200, 'Maximum 200 characters supported'),
  lastName: z
    .string({ required_error: 'Last name is required' })
    .min(3, 'Minimum 3 characters required')
    .max(200, 'Maximum 200 characters supported'),
  emailAddress: z.string().email(),
  phone: z.string().optional(),
  mobile: z.string().optional(),
  status: z.nativeEnum(UserStatus),
});

export type UserFormValues = z.infer<typeof userFormSchema>;

export const userRolesFormSchema = z.object({
  id: z.string(),
  roles: z.string().array(),
});

export type UserRolesFormValues = z.infer<typeof userRolesFormSchema>;

export const UserUtil = {
  parseUsers(json: any): IUserEntity[] {
    if (!json) {
      return [];
    }

    const users: IUserEntity[] = [];
    for (const user of json) {
      users.push({
        id: toString(get(user, 'id')),
        colour: toNumber(get(user, 'colour')),
        status: UserUtil.parseUserStatus(toString(get(user, 'status'))),
        initials: toString(get(user, 'initials')),
        lastName: toString(get(user, 'lastName')),
        revision: toNumber(get(user, 'revision')),
        userName: toString(get(user, 'userName')),
        firstName: toString(get(user, 'firstName')),
        emailAddress: toString(get(user, 'emailAddress')),
        roles: toArray(get(user, 'roles')).map((roleId) => toString(roleId)),
        councilCode: toString(get(user, 'councilCode')),
      });
    }
    return users;
  },
  parseUserStatus(status?: string) {
    for (const value of Object.values(UserStatus)) {
      if (value === UserStatus.Retired) {
        return UserStatus.Inactive;
      }
      if (status === value) {
        return value;
      }
    }
    return UserStatus.Unknown;
  },

  convertToFormValues(user?: IUserEntity): UserFormValues {
    return {
      id: toString(user?.id),
      firstName: toString(user?.firstName),
      lastName: toString(user?.lastName),
      emailAddress: toString(user?.emailAddress),
      phone: toString(user?.phone),
      mobile: toString(user?.mobile),
      status: this.parseUserStatus(user?.status),
    };
  },

  convertToUserRolesFormValues(user: IUserEntity): UserRolesFormValues {
    return {
      id: toString(user.id),
      roles: toArray(user.roles),
    };
  },
};
