import { z } from 'zod';
import toString from 'lodash/toString';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

export enum NotificationStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

const submissionNotificationSchema = z.object({
  id: z.string(),
  response: z.string(),
  receivedDateTime: z.string(),
});

type ISubmissionNotificationEntity = z.infer<typeof submissionNotificationSchema>;

export const notificationSchema = z.object({
  id: z.string(),
  status: z.string(),
  endDateTime: z.string(),
  submissions: submissionNotificationSchema.array(),
  startDateTime: z.string(),
  submissionsCount: z.number(),
});

export type INotificationEntity = z.infer<typeof notificationSchema>;

export const NotificationUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(NotificationStatus)) {
      if (status === value) {
        return value;
      }
    }
    return NotificationStatus.InProgress;
  },

  parseNotification(json: any): INotificationEntity {
    const notification = {
      id: toString(get(json, 'id')),
      status: this.parseStatus(get(json, 'status')),
      endDateTime: toString(get(json, 'endDateTime')),
      submissions: this.parseSubmissionNotifications(get(json, 'submissions')),
      startDateTime: toString(get(json, 'startDateTime')),
      submissionsCount: toNumber(get(json, 'submissionsCount')),
    };
    return notification;
  },
  parseNotifications(json: any[]): INotificationEntity[] {
    const notifications: INotificationEntity[] = [];
    for (const notification of json) {
      notifications.push(this.parseNotification(notification));
    }
    return notifications;
  },

  parseSubmissionNotification(json: any): ISubmissionNotificationEntity {
    const notificationSubmission = {
      id: toString(get(json, 'id')),
      response: toString(get(json, 'response')),
      receivedDateTime: toString(get(json, 'receivedDateTime')),
    };
    return notificationSubmission;
  },

  parseSubmissionNotifications(json: any): ISubmissionNotificationEntity[] {
    const notificationSubmissions: ISubmissionNotificationEntity[] = [];
    for (const notification of json) {
      notificationSubmissions.push(this.parseSubmissionNotification(notification));
    }
    return notificationSubmissions;
  },
};
