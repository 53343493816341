import React from 'react';

import { Box, Grid, Menu, MenuItem, styled } from '@mui/material';
import { FontIcon } from '@odl/core';
import AboutDialog from 'components/AboutDialog/AboutDialog';
import { useTranslation } from 'react-i18next';
import { useStoreDispatch, useStoreSelector } from 'store/hooks';
import { selectAuthenticationState, signOut } from 'store/app-state/authentication/authentication';

import Spacer from 'components/Spacer/Spacer';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { urlHelper } from 'components/RouteController/functions/urlHelper';
import { useNavigate } from 'react-router-dom';
import { UserAvatarType } from 'components/UserAvatar/UserAvatar.types';

type Props = {
  compact?: boolean;
};

const UserNavItem: React.FC<Props> = ({ compact = false }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const { userFirstName, userLastName, userEmail } = useStoreSelector(selectAuthenticationState);
  const displayName = [userFirstName, userLastName].join(' ');
  const initials = `${userFirstName[0] + userLastName[0]}`;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showAboutDialog, setShowAboutDialog] = React.useState<boolean>(false);

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleClickOpenMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickAbout = () => {
    setAnchorEl(null);
    setShowAboutDialog(true);
  };

  const handleClickLogout = React.useCallback(async () => {
    setAnchorEl(null);
    await dispatch(signOut());
    dispatch({ type: 'RESET' });
    navigate(urlHelper.getURLForSignedOut());
  }, [dispatch, navigate]);

  return (
    <Box data-testid="UserNavItem">
      <StyledBox
        height={36}
        display="flex"
        alignItems="center"
        role="button"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClickOpenMenu}
      >
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid container item xs justifyContent="flex-end" alignItems="center">
            <UserAvatar initials={initials} type={UserAvatarType.Solid} username={userEmail} />
            <Spacer x={2} />
            <StyledTitle>{displayName}</StyledTitle>
            <Spacer x={2} />
            <StyledFontIcon name="i-navigation-chevron-down" />
          </Grid>
        </Grid>
      </StyledBox>
      <StyledMenu
        id="user-menu"
        data-testid="user-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={anchorEl !== null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={handleClickAbout}>
          <Grid container direction="row" alignContent="center">
            <Grid item xs={1}>
              <StyledFontIcon name="i-status-information" />
            </Grid>
            <Spacer x={2} />
            <Grid container item xs alignContent="center">
              <Box>{t('About').toUpperCase()}</Box>
            </Grid>
          </Grid>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClickLogout}>
          <Grid container direction="row" alignContent="center">
            <Grid item xs={1}>
              <StyledFontIcon name="i-operations-logout" />
            </Grid>
            <Spacer x={2} />
            <Grid container item xs alignContent="center">
              <Box>{t('Logout').toUpperCase()}</Box>
            </Grid>
          </Grid>
        </StyledMenuItem>
      </StyledMenu>
      <AboutDialog open={showAboutDialog} onClose={() => setShowAboutDialog(false)} />
    </Box>
  );
};

export default UserNavItem;

const StyledBox = styled('div')(({ theme }) => ({
  cursor: 'pointer',
}));

const StyledTitle = styled('div')(({ theme }) => ({
  color: theme.palette.objective.light.grey[900],
  fontSize: `14px`,
  fontWeight: 600,
}));

const StyledFontIcon = styled(FontIcon)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  color: theme.palette.objective.light.grey[800],
  paddingTop: '6px',
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: '12px',
  '& .MuiListItem-root': {
    minWidth: '192px',
    lineHeight: '36px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiPaper-root': {
    color: theme.palette.objective.light.grey[900],
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: '192px',
  height: '44px',
  alignContent: 'center',
  backgroundColor: theme.palette.objective.light.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.objective.light.background.hover,
  },
}));
