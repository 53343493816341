import React from 'react';
import { Grid, styled, Typography } from '@mui/material';
import { FontIcon, IconButton } from '@odl/core';
import { useNavigate } from 'react-router-dom';

type Props = {
  title?: string;
  children: React.ReactNode;
  hasBackButton?: boolean;
  backURL?: string;
  pageHeaderActions?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
};
const PageLayout: React.FC<Props> = ({
  children,
  title,
  hasBackButton = false,
  backURL,
  pageHeaderActions,
  breadcrumbs,
}) => {
  const navigate = useNavigate();

  const handleOnClickBackButton = React.useCallback(() => {
    if (backURL) {
      navigate(backURL);
      return;
    }
    navigate(-1);
  }, [backURL, navigate]);

  return (
    <StyledPageContainer data-testid="PageLayout">
      {breadcrumbs && <StyledBreadCrumb>{breadcrumbs}</StyledBreadCrumb>}
      <StyledPageTitleBar container>
        <StyledPageTitleLeftColumn>
          {hasBackButton && (
            <IconButton size={'small'} onClick={handleOnClickBackButton}>
              <FontIcon name={'i-navigation-arrow-left'} />
            </IconButton>
          )}
          {title && <StyledPageTitle variant={'h1'}>{title}</StyledPageTitle>}
        </StyledPageTitleLeftColumn>
        {pageHeaderActions}
      </StyledPageTitleBar>
      {children}
    </StyledPageContainer>
  );
};

export default PageLayout;

const StyledPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  width: '100%',
  ...theme.mixins.flexGap(theme.spacing(2)),
}));

const StyledBreadCrumb = styled('div')(({ theme }) => ({}));

const StyledPageTitleBar = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  ...theme.mixins.flexGap(theme.spacing(1.5)),
}));

const StyledPageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '24px',
  margin: 0,
  color: theme.palette.objective.light.text.primary,
}));

const StyledPageTitleLeftColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...theme.mixins.flexGap(theme.spacing(1)),
}));
