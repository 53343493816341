import React from 'react';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { FontIcon, IconButton } from '@odl/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { urlHelper } from 'components/RouteController/functions/urlHelper';
import {
  selectAllAssignedApplications,
  selectCurrentUserAssignedApplications,
} from 'store/domain-data/application/application';
import { useSortedApplications } from 'pages/applications/functions/useSortedApplications';
import { ApplicationSortCriteria } from 'models/ApplicationSortCriteria.model';
import { SortDirection } from 'models/SortDirection.model';
import { ApplicationStatus, ApplicationTab, IApplicationEntity } from 'models/Application.model';
import ApplicationNotificationChip from 'components/ApplicationNotificationChip/ApplicationNotificationChip';
import ApplicationReferralChip from 'components/ApplicationReferralChip/ApplicationReferralChip';
import ApplicationComplianceChip from 'components/ApplicationComplianceChip/ApplicationComplianceChip';
import ApplicationSiteVisitsChip from 'components/ApplicationSiteVisitsChip/ApplicationSiteVisitsChip';
import ApplicationRFIsChip from 'components/ApplicationSiteRFIsChip/ApplicationRFIsChip';
import { sortBy } from 'lodash';
import { useDetailsPanelProps } from 'hooks/useDetailsPanelProps';
import { useStoreSelector } from 'store/hooks';

type Props = {
  type: ApplicationTab;
};

interface AppTableHeaderCell {
  sortCriteria: ApplicationSortCriteria;
  label: string;
  width: string;
  order: number;
}

const ApplicationsList: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const currentApplicationId = React.useMemo(() => {
    return params.get('applicationId');
  }, [params]);

  const _allUserApplications = useStoreSelector(selectCurrentUserAssignedApplications);
  const _assignedApplications = useStoreSelector(selectAllAssignedApplications);

  const _applications = React.useMemo(() => {
    if (type === ApplicationTab.Active) {
      return _allUserApplications;
    }
    if (type === ApplicationTab.Assigned) {
      return _assignedApplications;
    }
    return [];
  }, [_allUserApplications, _assignedApplications, type]);
  const { isCollapsed, setCollapsed } = useDetailsPanelProps();

  const [sortDirection, setSortDirection] = React.useState(SortDirection.DESC);
  const [sortCriteria, setSortCriteria] = React.useState(ApplicationSortCriteria.ProcessingDays);

  const sortedApplications = useSortedApplications({
    applications: _applications,
    sortCriteria,
    sortDirection,
  });

  // If a row is clicked on then set the select application to application id of the row
  const onRowClick = React.useCallback(
    async (application: IApplicationEntity) => {
      navigate(`?applicationId=${application.id}`);
      if (!isCollapsed && currentApplicationId === application.id) {
        setCollapsed(true);
        return;
      }
      setCollapsed(false);
    },
    [currentApplicationId, isCollapsed, navigate, setCollapsed],
  );

  const onActionClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, applicationId: string) => {
    event.stopPropagation();
    navigate(urlHelper.getURLForApplicationDetailsPage(applicationId));
  };

  // A list of application table header objects.
  // Label is displayed and id is used to select the correct field when sorting the table
  const appTableHeaderCells: AppTableHeaderCell[] = React.useMemo(() => {
    const tableHeaderCells = [
      {
        sortCriteria: ApplicationSortCriteria.ProcessingDays,
        label: 'Age',
        width: 'xs',
        order: 1,
      },
      {
        sortCriteria: ApplicationSortCriteria.ApplicationReference,
        label: 'DA #',
        width: 'small',
        order: 2,
      },
      {
        sortCriteria: ApplicationSortCriteria.Address,
        label: 'Address',
        width: 'medium',
        order: 3,
      },

      {
        sortCriteria: ApplicationSortCriteria.Notification,
        label: 'Notifications',
        width: 'small',
        order: 5,
      },
      {
        sortCriteria: ApplicationSortCriteria.Referrals,
        label: 'Referrals',
        width: 'small',
        order: 6,
      },
      {
        sortCriteria: ApplicationSortCriteria.ComplianceChecklists,
        label: 'Compliance',
        width: 'small',
        order: 7,
      },
      {
        sortCriteria: ApplicationSortCriteria.SiteVisits,
        label: 'Site Visit',
        width: 'small',
        order: 8,
      },
      {
        sortCriteria: ApplicationSortCriteria.RFIs,
        label: 'RFI',
        width: 'small',
        order: 9,
      },
    ];

    if (isCollapsed) {
      tableHeaderCells.push({
        sortCriteria: ApplicationSortCriteria.Description,
        label: 'Description',
        width: 'medium',
        order: 4,
      });
    }

    return sortBy(tableHeaderCells, ['order']);
  }, [isCollapsed]);

  const handleOnHeaderClick = React.useCallback(
    (_sortCriteria: ApplicationSortCriteria) => {
      if (sortCriteria === _sortCriteria) {
        setSortDirection(sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
        return;
      }
      setSortCriteria(_sortCriteria);
    },
    [sortCriteria, sortDirection],
  );

  return (
    <StyledTableContainer>
      <Table aria-label="table" size={'small'}>
        <TableHead>
          <TableRow>
            {appTableHeaderCells.map((appTableHeader) => (
              <TableCell
                id={appTableHeader.width === 'xs' ? 'xs' : undefined}
                size={appTableHeader.width === 'medium' ? 'medium' : 'small'}
                color={theme.palette.objective.light.info.light}
                key={appTableHeader.sortCriteria}
              >
                <TableSortLabel
                  onClick={() => handleOnHeaderClick(appTableHeader.sortCriteria)}
                  active={sortCriteria === appTableHeader.sortCriteria}
                  direction={sortDirection}
                >
                  {t(appTableHeader.label)}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell id="xs">{t('Action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedApplications?.map((entry) => (
            <TableRow
              id="xs"
              key={entry.id}
              onClick={() => onRowClick(entry)}
              selected={!isCollapsed && entry.id === currentApplicationId}
              className={entry.status === ApplicationStatus.NotStarted ? 'notStarted' : ''}
            >
              <TableCell id="xs">
                <Typography>{t('days_count', { count: entry.processingDays })}</Typography>
              </TableCell>
              <TableCell size="small">
                <Typography>{entry.applicationReference}</Typography>
              </TableCell>
              <TableCell size="medium" className="address">
                <Typography>{entry.fullAddress}</Typography>
              </TableCell>
              {isCollapsed && (
                <TableCell size="medium" className="description">
                  <Typography>{entry.description}</Typography>
                </TableCell>
              )}
              <TableCell size="small">
                <ApplicationNotificationChip applicationId={entry.id} />
              </TableCell>
              <TableCell size="small">
                <ApplicationReferralChip applicationId={entry.id} />
              </TableCell>
              <TableCell size="small">
                <ApplicationComplianceChip applicationId={entry.id} />
              </TableCell>
              <TableCell size="small">
                <ApplicationSiteVisitsChip applicationId={entry.id} />
              </TableCell>
              <TableCell size="small">
                <ApplicationRFIsChip applicationId={entry.id} />
              </TableCell>
              <TableCell id="xs" align="center">
                <IconButton
                  aria-label="application-action"
                  size={'small'}
                  onClick={(event) => onActionClick(event, entry.id)}
                >
                  <FontIcon name="i-navigation-arrow-right" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default ApplicationsList;

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& #xs': {
    width: '15px',
  },
  '& .MuiTableRow-root.MuiTableRow-head .MuiTableRow-root': {
    borderBottom: `1px solid ${theme.palette.objective.light.grey[700]}`,
  },
  '& .MuiTableCell-root.MuiTableCell-head': {
    whiteSpace: 'nowrap',
  },
  '& .MuiTableCell-root.MuiTableCell-body': {
    '&.address': {
      maxWidth: '200px',
      flex: 1,
      // truncate address text
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '400px',
      },
    },
    '&.description': {
      maxWidth: '200px',
      flex: 1,
      // truncate address text
      '& .MuiTypography-root': {
        // truncate description text
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '400px',
      },
    },
    '&.complete': {
      borderLeft: `3px solid ${theme.palette.objective.light.chip.green.contrastText}`,
      backgroundColor: theme.palette.objective.light.chip.green.main,
      color: theme.palette.objective.light.chip.green.contrastText,
      fontWeight: 600,
      '& .MuiBox-root': {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.objective.light.chip.green.contrastText,
      },
    },
    '&.inProgress': {
      borderLeft: `3px solid ${theme.palette.objective.light.chip.yellow.contrastText}`,
      backgroundColor: theme.palette.objective.light.chip.yellow.main,
      color: theme.palette.objective.light.chip.yellow.contrastText,
      fontWeight: 600,
      '& .MuiBox-root': {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.objective.light.chip.yellow.contrastText,
      },
    },
    '&.warning': {
      borderLeft: `2px solid ${theme.palette.objective.light.chip.red.contrastText}`,
      backgroundColor: theme.palette.objective.light.chip.red.main,
    },
  },
  '& .MuiTableBody-root .MuiTableRow-root': {
    height: '44px',
    '&:hover .MuiTableCell-root.MuiTableCell-body': {
      backgroundColor: theme.palette.objective.light.chip.blue.main,
      cursor: 'pointer',
    },
    '&.Mui-selected .MuiTableCell-root.MuiTableCell-body': {
      backgroundColor: theme.palette.objective.light.chip.blue.main,
    },
    '&.notStarted .MuiTypography-root': {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));
