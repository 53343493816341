export const ServiceErrorCode = {
  ClientError: 'ClientError',
  ServerError: 'ServerError',
  CognitoUsernameExists: 'CognitoUsernameExists',
  NetworkError: 'NetworkError',
  ResourceNotFound: 'ResourceNotFound',
  UserNotActive: 'UserNotActive',
};

const SERVICE_ERROR_NAME = 'ServiceError';

export class ServiceError extends Error {
  public code: string;

  constructor(code: string = ServiceErrorCode.ServerError, message?: string) {
    super(message ?? code);

    this.name = SERVICE_ERROR_NAME;
    this.code = code;
  }
}
