import React from 'react';
import { useStoreSelector } from 'store/hooks';
import { selectApplicationEntityById } from 'store/domain-data/application/application';
import { Grid, styled, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ODLTable from 'components/ODL/ODLTable/ODLTable';
import ODLTableRow from 'components/ODL/ODLTable/ODLTableRow';
import ODLTableCell from 'components/ODL/ODLTable/ODLTableCell';
import { useTranslation } from 'react-i18next';
import ODLOutlinedButton from 'components/ODL/ODLOutlinedButton/ODLOutlinedButton';
import { urlHelper } from 'components/RouteController/functions/urlHelper';
import ODLContainedButton from 'components/ODL/ODLOutlinedButton/ODLContainedButton';
import Spacer from 'components/Spacer/Spacer';
import { useDetailsPanelProps } from 'hooks/useDetailsPanelProps';

type Props = {};

const ApplicationDetailsSidePanel: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const applicationId = params.get('applicationId') || '';
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const { setCollapsed } = useDetailsPanelProps();

  React.useEffect(() => {
    return () => {
      setCollapsed(true);
    };
  }, [setCollapsed]);

  const handleOnClickView = React.useCallback(() => {
    navigate(urlHelper.getURLForApplicationDetailsPage(applicationId));
  }, [navigate, applicationId]);

  const handleOnClickEdit = React.useCallback(() => {
    navigate(urlHelper.getURLForEditApplicationDetailsPage(applicationId));
  }, [navigate, applicationId]);

  if (!application) {
    return null;
  }

  return (
    <StyledApplicationDetailsSidePanel>
      <StyledAddressBar>
        <Typography variant={'h2'}>{application.fullAddress}</Typography>
      </StyledAddressBar>
      <StyledODLTable>
        <StyledODLOutlinedButton onClick={handleOnClickView}>{t('View')}</StyledODLOutlinedButton>
        <ODLTableRow>
          <ODLTableCell width={150} isLabel={true}>
            {t('Reference')}
          </ODLTableCell>
          <ODLTableCell>{application.applicationReference}</ODLTableCell>
        </ODLTableRow>
        <ODLTableRow>
          <ODLTableCell isLabel={true}>{t('Applicant')}</ODLTableCell>
          <ODLTableCell>Applicant name</ODLTableCell>
        </ODLTableRow>
        <ODLTableRow>
          <ODLTableCell isLabel={true}>{t('Owner')}</ODLTableCell>
          <ODLTableCell>{application.ownerName}</ODLTableCell>
        </ODLTableRow>
        <ODLTableRow>
          <ODLTableCell isLabel={true}>{t('Contact')}</ODLTableCell>
          <ODLTableCell>Contact number</ODLTableCell>
        </ODLTableRow>
        <ODLTableRow>
          <ODLTableCell isLabel={true}>{t('Description ')}</ODLTableCell>
          <ODLTableCell>{application.description}</ODLTableCell>
        </ODLTableRow>
        <ODLTableRow>
          <ODLTableCell isLabel={true}>{t('Approver')}</ODLTableCell>
          <ODLTableCell>Approver name</ODLTableCell>
        </ODLTableRow>
      </StyledODLTable>
      <Spacer y={2} />
      <Grid container justifyContent="flex-end">
        <ODLContainedButton size={'large'} onClick={handleOnClickEdit}>
          {t('Edit Application')}
        </ODLContainedButton>
      </Grid>
    </StyledApplicationDetailsSidePanel>
  );
};

export default ApplicationDetailsSidePanel;

const StyledAddressBar = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '54px',
  alignItems: 'center',
}));

const StyledApplicationDetailsSidePanel = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledODLTable = styled(ODLTable)(({ theme }) => ({
  position: 'relative',
}));

const StyledODLOutlinedButton = styled(ODLOutlinedButton)(({ theme }) => ({
  position: 'absolute',
  right: 40,
  top: 0,
}));
