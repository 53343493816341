import { CardContent, Grid, Link, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InfoSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TermsCardContent>
      <Grid container direction="row">
        <Grid container item xs justifyContent="center">
          <Link
            component="button"
            variant="body2"
            onClick={() => window.open('https://www.objective.com', '_blank', 'noreferrer')}
          >
            <Typography>{t('Objective Corporation')}</Typography>
          </Link>
        </Grid>
        <Grid container item xs justifyContent="center">
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              window.open('https://www.objective.com.au/terms-of-service-objective-redact', '_blank', 'noreferrer')
            }
          >
            <Typography>{t('Terms of service')}</Typography>
          </Link>
        </Grid>
        <Grid container item xs justifyContent="center">
          <Link
            component="button"
            variant="body2"
            onClick={() => window.open('https://www.objective.com.au/privacy-policy', '_blank', 'noreferrer')}
          >
            <Typography>{t('Privacy Policy')}</Typography>
          </Link>
        </Grid>
      </Grid>
    </TermsCardContent>
  );
};

export default InfoSection;

const TermsCardContent = styled(CardContent)(({ theme }) => ({
  background: theme.palette.objective.light.background.papershade,

  padding: 18,
  '& .MuiTypography-root': {
    fontSize: '13px',
    fontWeight: 500,
    color: theme.palette.objective.light.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&.MuiCardContent-root': {
    paddingBottom: 18,
  },
}));
