import { z } from 'zod';

export enum PermissionName {
  ViewApplications = 'ViewApplications',
  ManageApplications = 'ManageApplications',
  Unknown = 'Unknown',
}

export enum PermissionStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Unknown = 'Unknown',
}

export const permissionSchema = z.object({
  id: z.string(),
  revision: z.number(),
  name: z.nativeEnum(PermissionName),
  description: z.string(),
  status: z.nativeEnum(PermissionStatus),
});

export type IPermissionEntity = z.infer<typeof permissionSchema>;

export const PermissionUtil = {
  parseName(name: string) {
    for (const value of Object.values(PermissionName)) {
      if (value === name) {
        return value;
      }
    }
    return PermissionName.Unknown;
  },

  parseStatus(status: string) {
    for (const value of Object.values(PermissionStatus)) {
      if (value === status) {
        return value;
      }
    }
    return PermissionStatus.Unknown;
  },
};
